@media (min-width: 992px) {
  .modal-dialog.modal-xlarge {
    max-width: 850px;
    margin: 1.75rem auto;
  }
  
  .codex-editor{
    margin-left: 30px !important;
  }
  .dashboard-main .dashboard-section .cards-container {
    /* padding: 20px 10px !important; */
  }
  .dashboard-main .dashboard-section .analytics-card {
    width: 310px !important;
    height: 300px !important;
    padding: 20px 15px !important;
  }
  .dashboard-main .dashboard-section .card-analytics-container {
    width: 280px !important;
    height: 170px !important;
  }
}
@media (min-width: 767px) {
  .modal-dialog.modal-large {
    max-width: 650px;
    margin: 1.75rem auto;
  }
  .modal-dialog.modal-mlarge {
    max-width: 750px;
    margin: 1.75rem auto;
  }
  .d-md-table-header-group {
    display: table-header-group !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  /* .dashboard-main .dashboard-section .project-details-main {
    padding: 15px 60px !important;
  } */
  .dashboard-main .dashboard-section .analytics-card {
    width: 350px !important;
    height: 350px !important;
    padding: 20px !important;
  }
  .dashboard-main .dashboard-section .card-analytics-container {
    width: 300px !important;
    height: 200px !important;
  }
}
@media (min-width: 1600px) {
  #wrapper {
    /* width: 1520px !important; */
  }
  
  .dashboard-main .left-side-bar{
    max-width: 400px;
  }

  .dashboard-main .dashboard-section{
    /* max-width: 1400px; */
  }
}
@media only screen and (max-width: 1458px) and (min-width: 1401px){
  .container.dashboard-container {
    max-width: 735px !important;
  }
  .container.dashboard-container .dashboard-analytics .w-100:has(.date-filter-dropdown){
    width: 100% !important;
  }
  .container.dashboard-container .dashboard-analytics .row .col-xxl-4 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 1400px) {
  #wrapper .dashboard-section .dashboard-container{
    max-width: 735px !important;
  }
}
@media (max-width: 847px){
  .dashboard-container {
      max-width: 365px !important;
  }
}
@media (max-width: 992px){
  .dashboard-container {
      max-width: 558px !important;
  }
  #wrapper .page-editor-main .right-side-bar .page-preview-link {
    width: 375px !important;
  }
  .cent-cont-u{
    max-width: 375px !important;
  }
  .activate-profile{
    width: 296px !important;
  }
  .integrations-toggle-tab {
    display: none !important;
    opacity: 0;
    visibility: hidden;
  }
  .integrations-tab-dropdown {
    display: flex !important;
  }
}
@media (min-width: 1400px) {
  /* #wrapper {
    width: 1320px;
  } */
  .dashboard-main .left-side-bar{
    max-width: 400px;
  }
  
  .manage-billing-main .left-side-bar{
    max-width: 400px;
  }
  
  .dashboard-main .dashboard-section{
    /* max-width: 1400px; */
  }
  /* .dashboard-main .dashboard-section .project-details-main{
    padding: 15px !important;
  } */
  .dashboard-main .dashboard-section .cards-container{
    padding: 20px 0 !important;
  }
  .dashboard-main .dashboard-section .dashboard-analytics {
    gap: 15px !important;
  }
  .dashboard-main .dashboard-section .card-analytics-container {
    width: 290px !important;
    height: 180px !important;
  }
  .dashboard-main .dashboard-section .analytics-card {
    width: 320px !important;
    height: 320px !important;
  }
  .dashboard-main .dashboard-section .social-analytics-card {
    width: 320px !important;
    height: 320px !important;
  }
  .dashboard-main .dashboard-section .social-analytics {
    padding: 10px 20px;
  }
  /* .dashboard-main .dashboard-section .project-details-main {
    padding: 15px 48px !important;
  } */
}
@media (max-width: 1200px) {
  #wrapper .project-links-main {
    flex-wrap: wrap;
    height: auto !important;
  }
  #wrapper .project-links-main .project-link {
    margin-bottom: 5px;
  }
  #wrapper .dashboard-analytics {
    flex-wrap: wrap;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-first-division {
    width: 40% !important;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-second-division {
    width: 60% !important;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-third-division {
    width: 100% !important;
    margin-top: 10px;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding-left: 0 !important;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-third-division .community {
    margin-right: 10px;
  }
}
@media (max-width: 1029px){
  .left-side-bar{
    transition: all 150ms linear !important;
    position: fixed !important;
    z-index: 96;
    height: 93% !important;
    top: 60px;
  }
  .left-side-bar.open{
    transform: translate3d(0, 0, 0) !important;
    top: 60px !important;
    box-shadow:8px 0px 40px 0px rgba(0, 0, 0, 0.10);
  }
  .editor-section{
    margin-left: 100px !important;
    flex-direction: column;
    gap: 50px !important;
  }
  /* .new-editor-iframe {
    min-height: 90%;
  } */
}
@media  only screen and (max-width: 1029px) and (min-width: 768px) {
  #wrapper .analytics-main .analytics-section,#wrapper .monetization-main .monetization-section,#wrapper 
  .community-main .community-section,.create-product-main{
    padding-left: 90px !important;
  }
}
/* @media (min-width: 1400px) {
  .dashboard-main .dashboard-section .project-details-main {
    padding: 15px 48px !important;
  }
} */
@media (max-width: 992px) {
  #wrapper .header-dropdown-main .header-links {
    display: none !important;
  }
  #wrapper .page-editor-main {
    height: auto !important;
  }
  #wrapper .page-editor-section {
    padding-top: 10px !important;
  }
  #wrapper .page-editor-section .header-links {
    display: flex !important;
    overflow-y: hidden;
    overflow-x: auto;
  }
  #wrapper .page-editor-main {
    flex-wrap: wrap !important;
    height: auto !important;
  }
  #wrapper .page-editor-main .page-editor-section {
    flex-basis: 100% !important;
  }
  #wrapper .page-editor-main .right-side-bar {
    flex-basis: 100% !important;
    padding-bottom: 80px !important;
  }
  #wrapper .page-editor-main .right-side-bar .page-preview-mobile {
    height: 600px !important;
    width: 375px !important;
  }
  /* #wrapper .page-editor-main .right-layout .page-preview-link {
    width: 90% !important;
  } */
  #wrapper .community-main {
    /* padding: 10px !important;
    padding-top: 10px !important; */
    /* flex-direction: column; */
    /* height: auto !important; */
  }
  #wrapper .community-main .community-section {
    flex-basis: 100% !important;
    /* padding: 30px 20px !important; */
    padding-top: 10px !important;
    width: 100%;
    margin-top: 20px !important;
  }
  #wrapper .community-main .header-links {
    display: flex !important;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding-left: 15px;
  }
  #wrapper .monetization-main .left-side-bar{
    display: inline-flex ;
  } 

  #wrapper .monetization-main .monetization-section {
    flex-basis: 100% !important;
    /* padding: 30px 20px !important; */
    padding: 15px !important;
    width: 100%;
  }
  #wrapper .monetization-main .header-links {
    display: flex !important;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding-left: 15px;
  }
  #wrapper .analytics-main {
    /* padding: 10px !important;
    padding-top: 10px !important;
    flex-direction: column;
    height: auto !important; */
  }
  #wrapper .analytics-main .analytics-section {
    flex-basis: 100% !important;
    /* padding: 30px 20px !important; */
    padding-top: 10px !important;
    width: 100%;
    margin-top: 20px !important;
  }

  #wrapper .analytics-main .header-links {
    display: flex !important;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding-left: 15px;
  }
  #customizePageModal .modal-dialog.modal-xlarge {
    max-width: 90vw;
    margin: 1.75rem auto;
  }
  #customizePageModal{
    max-width: 750px;
  }
  #wrapper .manage-billing-main .left-side-bar{
    display: none !important;
  }
  .upgradeTeamModal{
    width: 100% !important;
    /* height: calc(100vh - 220px) !important; */
  }
}
@media (max-width:1029px) and (min-width:768px) {
  #wrapper .page-editor-main .page-editor-section {
    padding-left:110px !important;
  }
}
@media (max-width: 860px) {
  #wrapper .dashboard-analytics {
    flex-wrap: wrap;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-first-division {
    width: 100% !important;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-second-division {
    width: 100% !important;
    padding-left: 0 !important;
    margin-top: 10px;
  }
  #wrapper .dashboard-analytics .dashboard-analytics-third-division {
    width: 100% !important;
    margin-top: 10px;
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 767px) and (min-width: 606px){
  #wrapper .dashboard-main .dashboard-section .project-details-main .project-details-div{
    justify-content: center !important;
    /* width: 60%  !important; */
    width: 88%  !important;
  }
  /* #wrapper .analytics-main .analytics-section,
  #wrapper .page-editor-main .right-layout,
  #wrapper .community-main .community-section,
  #wrapper .monetization-main .monetization-section,
  #wrapper .dashboard-main .dashboard-section{
    padding-bottom:90px !important ;
  } */
  }
@media (max-width: 767px){
  /* #wrapper .community-main .community-section{
    padding-bottom:90px !important ;
  } */
}
@media(max-width:495px){
  .sec-main-u .delete-all-btn svg{
    display: none !important;
  }
  .sec-main-u .delete-all-btn{
    padding: 5px !important;
  }
  .product-head-u{
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
@media(max-width:768px){
  #wrapper .dashboard-main .dashboard-section .project-details-main .project-details .project-link a{
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  #wrapper .left-side-bar:not(.settings-sidebar-for-mobile){
    display: none !important;
  }
  #wrapper .bottom-navbar{
    display: flex !important;
  }
  #wrapper .dashboard-main .left-side-bar {
    flex-basis: 100% !important;
  }
  #wrapper .dashboard-main .left-side-bar h4 {
    padding-left: 0 !important;
  }
  /* #wrapper .dashboard-main .left-layout .projects-main {
    min-height: calc(100vh - 250px) !important;
    min-height: calc(var(--app-height) - 220px) !important;
  } */
  #wrapper .dashboard-main .left-side-bar .projects-main .project-small {
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex-wrap: wrap;
  }
  #wrapper .dashboard-main .left-side-bar .projects-main .project-small .project-small-details-main {
    width: calc(100vw - 170px) !important;
    overflow-x: hidden;
  }
  #wrapper .dashboard-main .left-side-bar .projects-main .project-small.active {
    background-color: transparent !important;
  }
  #wrapper .dashboard-main .left-side-bar .projects-main .project-small .project-small-project-title span {
    
    width: 767px !important
  }
  #wrapper .dashboard-main .left-side-bar .projects-main .project-small .project-small-project-link span{
    
    width: 767px !important
  }
  #wrapper .dashboard-main .left-side-bar .upgrade-div {
    display: none !important;
  }
  /* #wrapper .dashboard-main .left-layout .account-div {
    display: none !important;
  } */
 /* #wrapper .dashboard-main .dashboard-section {
     display: none; 
  } */

  /* #wrapper .modal-content {
    padding: 1rem 0.2rem;
  } */
  #newNewsletterModal .modal-content {
    width: 100vw !important;
  }
  #newProductModal .modal-content {
    width: 100vw !important;
  }
  /* .analytics-card {
    flex-basis: 50%;
    margin-right: 0 !important;
    margin-top: 5px !important;
  } */
  .analytics-header {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }
  .analytics-header .analytics-filter-main {
    margin-bottom: 20px;
  }
  #customizePageModal{
    max-width: 95vw;
  }
  #wrapper .manage-billing-main .table-responsive{
    font-size: 13px !important;
  }
  #wrapper .manage-billing-main .project-small-user-pp img{
    width: 30px;
  }
  #wrapper .manage-billing-main table tbody tr td .badge{
    font-size: 12px !important;
  }
  #wrapper .manage-billing-main .manage-billings{
    font-size: 12px !important;
  }
  #wrapper .page-editor-main .page-editor-section {
    padding: 30px !important;
  }
  .mobile-container {
    height: 56% !important;
  }
  .editor-section {
    margin-left: 30px !important;
    margin-bottom: 80px !important;
  }
  .content-block-main-text {
    /*max-width: 60px !important;*/
  }
  .content-block-main-desc {
    /*max-width: 150px !important;*/
    font-size: 12px;
  }
}
@media (max-width: 862px){
  /* #wrapper .dashboard-main .dashboard-section .project-details-main{
    padding: 15px 29px !important;
  } */
  #wrapper .dashboard-main .dashboard-section .project-details-main .cards-container{
   justify-content: center !important;
  }
  .project-details-div {
    margin-left: 10px;
  }
  .date-btns-selector{
    justify-content: center !important;
  }
  #wrapper .dashboard-section .dashboard-container{
    /*max-width: 385px !important;*/
  }
  .container.dashboard-container .dashboard-analytics .row .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media (max-width: 729px) {
  header .page-published{
    display: none !important;
  }
}
@media (max-width: 660px) {
  #wrapper header .upgrade-main-btn {
    margin-right: 0 !important;
  }
  #wrapper .page-editor-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: transparent !important;
  }
  #wrapper .page-editor-section .header-links {
    padding-left: 10px !important;
  }
  #wrapper .page-editor-section .editor-header {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  #wrapper .page-editor-section .editor-header button {
    padding: 15px 5px !important;
  }
  #wrapper .page-editor-section .editor-header .btn-add-block {
    margin-right: 10px !important;
  }
  #wrapper .page-editor-section .editor-header .btn-customize-page {
    font-size: 14px !important;
  }
  #wrapper .page-editor-section .editor-links-main {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  #wrapper .page-editor-section .editor-links-main .editor-link {
    box-shadow: 0px 4px 24px rgba(73, 70, 96, 0.1);
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0 !important;
    height: 100% !important;
  }
  #wrapper .page-editor-section .editor-links-main .editor-link .editor-link-first-half {
    padding: 15px;
    width: 100%;
  }
  #wrapper .page-editor-section .editor-links-main .editor-link .editor-link-second-half {
    padding: 15px;
    border-top: 1px solid #E7E6EA;
    width: 100%;
    justify-content: space-between !important;
  }
  #wrapper .page-editor-section .editor-links-main .editor-link-collapsible {
    padding-left: 30px !important;
  }
  .new-block-div {
    flex-basis: 50% !important;
  }
  /* .custom-domain-input-group {
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 15px 10px !important;
  } */
  .custom-domain-input-group p {
    margin-bottom: 10px !important;
  }
  .modal-content{
    max-width: 96vw !important;
  }
  #wrapper .page-editor-main .right-side-bar .page-preview-mobile{
    width: 100% !important;
  }
  #wrapper .page-editor-main .right-side-bar .page-preview-link {
    width: 100% !important;
  }
  /* .content-block-main {
    flex-direction: column;
    min-height: auto !important;
    gap: 0px !important;
  }
  .content-block-main .first-half {
    border-bottom: 1px solid #e7e6ea;
  } */
}
@media (max-width: 600px) {
  .domain-btns-desktop {
    display: none !important;
  }
  .domain-btns-mobile {
    display: flex !important;
  }
  .custom-domain-input-group.domain-enabled {
    margin-right:0 ;
  }
  .dns-table {
    display: flex;
  }
  .dns-table-header,.dns-table-row {
    display: flex !important;
    flex-direction: column !important;
    background-color: transparent !important;
    width: 100%;
    justify-content: flex-start;
    border: none !important;
  }
  .dns-table-header .dns-header-cell:nth-child(odd),
  .dns-table-row .dns-table-cell:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.02) !important;
  }
  .dns-table-header .dns-header-cell,
  .dns-table-row .dns-table-cell {
    border-bottom: 1px solid rgba(107, 107, 107, 0.10) !important;
  }
  .dns-table-cell {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
  .dns-status-sub-desc{
    margin-top: 15px;
    display: flex;
  }
  .dns-status-desc {
    margin-top: 15px;
  }
}
@media (max-width: 379px){
  #wrapper .dashboard-main .dashboard-section .project-details-main .cards-container,#wrapper .dashboard-main .dashboard-section .project-details-main .project-details-div{
    justify-content: center !important;
  }

  .content-block-main-text {
    /*max-width: 20px !important;*/
  }
  .content-block-main-desc {
    /*max-width: 120px !important;*/
  }
  .editor-section {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
/* @media (max-width: 651px){
  .codex-editor--narrow .codex-editor__redactor{
    margin-right: 0 !important;
    margin-left: 70px !important;
  }
} */
@media (min-width: 651px){
  .codex-editor--narrow .ce-toolbar__actions{
    right: unset !important;
    /* left: -20px !important; */
  }
  .codex-editor--narrow .codex-editor__redactor{
    margin-right: 0 !important;
    margin-left: 50px !important;
  }
  .codex-editor--narrow .ce-toolbox .ce-popover{
    right: unset !important;
    left: -30px !important;
  }
}
/*# sourceMappingURL=media_queries.css.map */
@media (max-width: 450px) {
  .domain-first-step-fields{
    display: block !important;
  }
  .first-step-name-field,.first-step-ttl-field{
    display: none;
  }
  .first-step-value-field{
    width: 100% !important;
  }
  #wrapper .settings-section {
    background: none !important;
  }
  #wrapper .page-editor-main .page-editor-section {
    padding: 15px !important;
  }
  .connect-domain-container,
  .domain-section-container {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}
@media (max-width: 533px) {
  .recent-referral-last-column {
    display: none !important;
  }
  .recent-referral-last-column-heading {
    margin-right: 0;
  }
  .affiliate-program-page {
    background-color: #fafafa;
  }
  .affiliate-analytics {
    flex-wrap: wrap;
  }
  .affiliate-program-page-analytics {
    width: 445px !important;
    width: 100%;
  }
  .editor-section {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .design-section .project-theme-wheel {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

@media (max-width: 475px) {
  .design-section .zaap-normal-text {
    font-size: 12px !important;
  }
  .design-section .project-theme-wheel .text-grey-u.fs-12px {
    font-size: 10px !important;
  }
  .color-code {
    font-size: 10px !important;
  }
}