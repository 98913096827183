/* #wrapper {
  max-width: 1520px;
} */
.upgradeTeamModal {
  width: 100%;
  left: unset;
  right: 0;
  /* height: calc(100vh - 70px); */
  /* height: 100dvh; */
  top: unset;
  bottom: 0;
  /* background-color: red; */
  background: rgba(255, 255, 255, 0.5) !important;
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 99 !important;
  inset: 0 !important;
}

/* .upgradeTeamModal .modal-body {
  padding-right: 50px;
} */