#wrapper .community-main {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  position: fixed;
}
#wrapper .community-main .community-section {
  flex-basis: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 22px 35px 0 35px;
  position: relative;
}
#wrapper .community-main .community-section .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#wrapper .community-main .community-section .section-main {
  width: 100%;
  display: flex;
  height: calc(var(--app-height) - 138px) !important;
  justify-content: space-between;
  flex-direction: column;
}
#wrapper .community-main .community-section .section-main .search-bar {
  flex: 3;
}
#wrapper .community-main .community-section .section-main .search-bar input {
  font-weight: 500 !important;
}
#wrapper .community-main .community-section .section-main .search-bar input::-moz-placeholder {
  font-weight: 500 !important;
}
#wrapper .community-main .community-section .section-main .search-bar input:-ms-input-placeholder {
  font-weight: 500 !important;
}
#wrapper .community-main .community-section .section-main .search-bar input::placeholder {
  font-weight: 500 !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}
#wrapper .community-main .community-section .section-main .filter-dropdown::after {
  display: none !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown:hover {
  background-color: #F3F3F3 !important;
}
#wrapper .community-main .community-section .section-main .table-action-dropdown::after {
  display: none !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown.show {
  background-color: #F3F3F3 !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown-menu li a:hover {
  background-color: #F3F3F3 !important;
  color: inherit !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown-menu li a span img,#wrapper .community-main .community-section .section-main .filter-dropdown-menu-channel li a span img {
  opacity: 0;
}
#wrapper .community-main .community-section .section-main .filter-dropdown-menu li a.active,#wrapper .community-main .community-section .section-main .filter-dropdown-menu-channel li a.active {
  background-color: #F3F3F3 !important;
  color: #4397F7 !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown-menu li a.active span {
  font-size: 16px !important;
}
#wrapper .community-main .community-section .section-main .filter-dropdown-menu li a.active span img,#wrapper .community-main .community-section .section-main .filter-dropdown-menu-channel li a.active span img {
  opacity: 1;
  margin-top: -4px !important;
}
#wrapper .community-main .community-section .btn-import {
border-radius: 12px !important;
}
#wrapper .community-main .community-section .section-main table thead {
  border-top: 1px solid #E8E9EA;
  border-bottom: 1px solid #E8E9EA;
}
#wrapper .community-main .community-section .section-main table thead th {
  font-weight: 500;
  color: #5B5B5B;
}
#wrapper .community-main .community-section .section-main table tbody {
  border-top: none !important;
  border-bottom: 1px solid #E8E9EA;
}
#wrapper .community-main .community-section .section-main table tbody tr {
  border: none !important;
  border-bottom: 1px solid #E8E9EA !important;
}
#wrapper .community-main .community-section .section-main table tbody tr td {
  vertical-align: middle;
  border: none !important;
  font-weight: 500;
}
#wrapper .community-main .community-section .section-main table tbody tr td .subscriber-name-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  background-color: #03ACFF;
  color: white;
  border-radius: 50px;
}
#wrapper .community-main .community-section .section-main table tbody tr td .table-action-dropdown {
  cursor: pointer;
  padding: 10px;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge {
  padding: 10px 15px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-subscribed {
  color: #9B1DFF;
  background-color: #F2EEFF;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-subscribed svg {
  fill: #9B1DFF;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-unsubscribed {
  color: #FF1D53;
  background-color: #FFEEF1;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-unsubscribed svg {
  fill: #FF1D53;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-customer {
  color: #FFC01D;
  background-color: #FFFAEE;
}
#wrapper .community-main .community-section .section-main table tbody tr td .badge-customer svg {
  fill: #FFC01D;
}
#wrapper .community-main .community-section .section-main table .font-2x {
  font-size: 30px;
}
#wrapper .community-main .community-section .section-main .table > :not(caption) > * > * {
  border: none !important;
}
#wrapper .community-main .community-section .section-main .section-footer {
  width: 100%;
  padding: 10px 0;
  margin-top: 22px;
  margin-bottom: 9px;
  margin-left: 10px;
}
.section-main .section-footer .table-pagination {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 9px;
}
.section-main .section-footer .table-pagination button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: #EEEDEE;
  color: #6B6B6B;
  border-radius: 10px !important;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
}
.section-main .section-footer .table-pagination button:hover {
  background-color: #212322 !important;
  color: white !important;
}
.section-main .section-footer .table-pagination button.active {
  background-color: #212322 !important;
  color: white !important;
}
#wrapper .community-main .community-section::-webkit-scrollbar {
  width: 0.5em;
}
#wrapper .community-main .community-section::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}
#newNewsletterModal{
  margin: 0 !important;
}
#newNewsletterModal .modal-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-mails-modal #newNewsletterModal,
.create-posts-modal #newNewsletterModal{
  max-width: 750px !important;
}

.create-mails-modal #newNewsletterModal .modal-dialog,
.create-posts-modal #newNewsletterModal .modal-dialog{
  max-width: 100% !important;
}

#newNewsletterModal .modal-content .send-newsletter-header {
  border-bottom: 1px solid rgba(12, 2, 43, 0.1) !important;
}
#newNewsletterModal .modal-content .send-newsletter-header .close {
  font-size: 2.5rem !important;
}
#newNewsletterModal .modal-content .send-newsletter-footer {
  width: 100%;
  background-color: #FAFAFA;
  padding: 20px 20px;
  display: flex;
  align-items: center;
}
#newNewsletterModal .modal-content .send-newsletter-footer .close {
  border: 1px solid #E8E9EA;
  box-shadow: 0px 4px 15px rgba(49, 48, 67, 0.05);
  padding: 10.5px 15px !important;
}
#newNewsletterModal .modal-content .send-newsletter-footer button {
  padding: 10px 15px !important;
}
#newNewsletterModal .modal-content .modal-body {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
#newNewsletterModal .modal-content .modal-body #sendNewsletterContent {
  /* background: #F1F1F1 !important; */
  border-radius: 20px;
  margin-top: 10px;
}
#newNewsletterModal .modal-content .modal-body #sendNewsletterContent #content-card{
  background: #F1F1F1 !important;
  border-radius: 12px;
  margin-top: 10px;
  padding:10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#newNewsletterModal .modal-content .modal-body #sendNewsletterContent #content-card span{
  font-weight: 500;
  color: #333333;
}
#newNewsletterModal .modal-content .modal-body .template-body {
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 15px;
  overflow-x: hidden;
}
#newNewsletterModal .modal-content .modal-body .template-body #email-subject-templte-field {
  border: none;
  outline: none;
  font-size: 30px !important;
  width: 100%;
  font-weight: bold;
}
#newNewsletterModal .modal-content .modal-body .template-body #email-subject-templte-field::-moz-placeholder {
  color: rgba(14, 18, 59, 0.25);
}
#newNewsletterModal .modal-content .modal-body .template-body #email-subject-templte-field:-ms-input-placeholder {
  color: rgba(14, 18, 59, 0.25);
}
#newNewsletterModal .modal-content .modal-body .template-body #email-subject-templte-field::placeholder {
  color: rgba(14, 18, 59, 0.25);
}
#newNewsletterModal .modal-content .modal-body .template-body .add-block-template-field {
  color: rgba(14, 18, 59, 0.25);
  font-weight: bold;
  font-size: 14px;
}
#newNewsletterModal .modal-content .modal-body .template-body .add-block-template-field:hover {
  color: rgba(14, 18, 59, 0.25);
}
#newNewsletterModal .modal-content .modal-body .template-body .add-block-template-field:focus,
#newNewsletterModal .modal-content .modal-body .template-body .add-block-template-field:active {
  outline: none;
  box-shadow: none;
  /* border:none; */
}
#newNewsletterModal .modal-content .modal-body .template-body::-webkit-scrollbar {
  width: 0.25em;
}
#newNewsletterModal .modal-content .modal-body .template-body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}
#newNewsletterModal .modal-content .modal-body .template-body::-webkit-scrollbar-thumb {
  background-color: rgba(68, 68, 68, 0.5);
  /* outline: 1px solid rgb(68, 68, 68); */
  border-radius: 50px;
}

.accordion-button::after {
  background-size: 12px !important;
  margin-top: 12px !important;
}

.accordion-button {
  background-color: #F1F1F1 !important;
  border-radius: 20px !important;
  color: inherit !important;
  padding: 1rem 1.25rem !important; 
}

.accordion-button img {
  border-radius: 0px !important;
}/*# sourceMappingURL=community.css.map */

/* import modal */
.import-subscribers-header{
  background: url('../assets/img/import-subscribers-header.png') no-repeat center;
    background-size: cover;
    padding: 120px 0 !important;
}
.import-modal-body {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  margin-top: -20px;
  background-color: white;
  box-shadow: 0 -1px 10px lightgrey;
  padding: 30px 20px;
}
.import-modal{
  
  display: block;
  padding-left: 17px;
}
.import-modal .modal-content .dropzone-wrapper4{
  height: 120px !important;
    border: 1px dashed #A0A0A0 !important;
    background-color: transparent;
}
.import-modal .modal-content .dropzone-wrapper4 .dropzone-desc{
  top: 25%;
}
.modal-subheader {
  padding: 0 1rem;
  border-bottom: 0;
  margin: 0;
}
.create-biolink-subheader, .walkthrough-subheader {
  font-family: Eudoxus Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 17px;
  color: #6B6B6B !important;
  padding: 10px 0px;
  margin: 0 auto
}
.import-csv-file-btn{
  padding: 15px 8px;
  font-size: 16px;
  border-radius: 35px;
  border: none;
}
/* uploaded csv */
.create-biolink-header{
  
  padding: 15px 25px;
  /* padding-bottom: 0.2rem; */
  border-bottom: 1px solid #E8E9EA;
}
#import_subscribers_form .form-group label {
  font-weight: 600;
  font-size: 14px;
}

#import_subscribers_form .form-group .form-select {
  background-color: #F6F6F6;
  color: rgba(14, 18, 59, 0.5);
  outline: none;
  border: none;
  border-color: transparent;
  /* background: #F7F7F8; */
  font-weight: 700;
  color: #20212b;
  border-radius: 20px;
  padding: 1.5rem 0.75rem

}
#import_subscribers_form  button{
  width: 45%;
  border-radius: 50px;
}

#wrapper .manage-billing-main .left-side-bar{
    align-items: start !important;
}

.toggle-input:checked + .toggle-label {
  background-color: #FF1451 !important;
}

.btn--default{
  background-color: #FF1451 !important;
  color: white !important;
  border-color: #FF1451 !important;
}

.btn--default:hover {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
}

.btn--default:active {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
  box-shadow: 0 0 0 0.25rem rgba(228, 6, 65, 0.5) !important;
}

.btn--default:focus {
  background-color: #e40641 !important;
  border-color: #e40641 !important;
  box-shadow: 0 0 0 0.25rem rgba(228, 6, 65, 0.5) !important;
}

.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		/* width: 320px; */
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

        
/*Right*/
	.modal.right.fade .modal-dialog {
    left: unset;
		right: -700px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

  .modal.right.fade .modal-content{
    border: none !important;
    border-radius: 0 !important;
  }

  .editor-template-body{
    width: 100%;
    min-height: 55vh;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    /* background-color: red; */
    /* margin-left: auto; */
    margin-top: 30px;
    padding-left: 10px;  
  }
  .codex-editor{
    width: calc(100% - 50px) !important;
    /* margin-left: 30px !important; */
  }
  .ct{
    z-index: 1999;
  }
  #newNewsletterModal .modal-content .product-dropzone {
    height: 60px !important;
    border: 1px dashed #A0A0A0 !important;
    border-radius: 20px;
    background-color: transparent;
    /* padding: 75px; */
  }
  #newNewsletterModal .modal-content .product-dropzone .dropzone-desc{
    top: 3px;
  }
  
  .ce-tune-alignment--left .anyButtonContainer__anyButtonHolder{
    text-align: left !important;
  }

  .ce-tune-alignment--center .anyButtonContainer__anyButtonHolder{
    text-align: center !important;
  }
  
  .ce-tune-alignment--right .anyButtonContainer__anyButtonHolder{
    text-align: right !important;
  }
  

.up-cont-u{
  width: 100%;
  height: calc(100dvh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  gap: 26px;
}
.price-area{
  max-width: 700px;
  width: 100%;
  display: flex;
  white-space: nowrap;
  color: #000;
  font-family: GT Walsheim Pro;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: end;
}
.disdate{
  position: relative;
  color: rgba(0, 0, 0, 0.50);
  font-family: GT Walsheim Pro;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 17px;
}
.disdate>svg{
  position: absolute;
  left: -10px;
}
.price-area{
  margin-bottom: 24px;

}
.newprice{
  color: #000;
  font-family: GT Walsheim Pro;
  font-size: 22px;
  font-weight: 500;
  margin-left: 22px;
  margin-right: 4px;
}
.plan-box{
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: absolute;
  top: 2px;
  left: 0;
}
.plane-line {
  width: 125%;
  height: 87px;
  border: solid 11px #000;
  border-color: #000 transparent transparent transparent;
  border-radius: 75%/112px 127px 0 0;
  transform: rotate(168deg) translateX(70px);
}
.plancircle {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: #000;
  border: 5px solid #F6F6F6;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.graycrcle {
  background-color: #5F5F5F;
  top: 100px;
  left: 95px;
}
.bluecrcle {
  background-color: #18F;
  top: 89px;
  left: 325px;
}
.yellowcrcle {
  background-color: #FFBA34;
  top: 42px;
  /* right: 105px; */
  left: 34rem;
}
.range-box{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
}
.ranepop {
  position: absolute;
  right: 147px;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ranepop>span{
  color: #FFF;
  text-align: center;
  font-family: GT Walsheim Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.21px;
  text-transform: capitalize;
  position: absolute;
  z-index: 5;
  margin-bottom: 10px;
}
.popup2 {
  right: 14px;
  bottom: 20px;
}
.rangecircle{
  width: 19px;
  height: 19px;
  border-radius: 100%;
  background-color: #0F615C;
  position: absolute;
  right: 204px;
}
.rangecircle2{
  width: 19px;
  height: 19px;
  border-radius: 100%;
  background-color: #0F615C;
  position: absolute;
  right: 71px;
}
.range-line{
  width: 100%;
  height: 10px;
  background-color: #e7e7e7;
  border-radius: 10px;
}
.mobsvg{
  display: none;
}
@media(max-width : 1029px){
  .up-cont-u{
    width: calc(100% - 78px);
    margin-left: 78px;
  }
}
@media(max-width : 768px){
  .up-cont-u{
    width: 100%;
    margin-left:0;
  }
  .upgrade-card {
    box-shadow: none;
    border-radius: 0;
  }
  .plan-button-container{
    margin-top: 14px;
  }
}
@media(max-width : 700px){
  .up-cont-u {
    padding: 0;
    gap: 0;
  }
  .plan-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .community-main .plan-card {
    max-width: 420px !important;
    width: 100% !important;
    min-height: 213px !important;
    height: fit-content !important;
  }
 
  .plan-card{
    box-shadow: none;
  }
  .upgrade-card2 {
    border-radius: 0;
    box-shadow: 0 ;
    margin-top: 220px;
    background-color: transparent;
}
}

@media(max-width : 630px){
  .plan-box>svg{
    width: 640px;
  }
  .yellowcrcle {
    top: 40px;
    left: 32rem;
  }
  .bluecrcle {
    top: 83px;
    left: 315px;
  }
}
@media(max-width : 590px){
  .plan-box>svg{
    width: 600px;
  }
  .yellowcrcle {
    top: 50px;
    left: 28rem;
  }
  .bluecrcle {
    top: 82px;
    left: 280px;
  }
  .graycrcle {
    top: 93px;
    left: 85px;
  }
  
}
@media(max-width : 575px){
  .upgrade-card2 {
    margin-top: 210px;
  }
}
@media(max-width : 530px){
  .plan-box>svg{
    width: 540px;
  }
  .graycrcle {
    top: 89px;
    left: 80px;
  }
  .bluecrcle {
    top: 77px;
    left: 255px;
  }
  .yellowcrcle {
    top: 37px;
    left: 27rem;
  }
  .range-box{
    display: none;
  }
}
@media(max-width : 500px){
  .tabsvg{
    display: none;
  }
  .mobsvg{
    display: block;
  }
  .plan-box>svg {
    width: 510px;
  }
  .plan-cards-container {
    margin-top: 7rem;
  }
  .graycrcle {
    top: 145px;
    left: 27px;
  }
  .bluecrcle {
    top: 123px;
    left: 244px;
  }
  .yellowcrcle {
    top: 45px;
    left: 26rem;
  }
  .upgrade-card2 {
    margin-top: 270px;
  }
}
@media(max-width : 460px){
  .plan-box>svg {
    width: 473px;
  }
  .graycrcle {
    top: 133px;
    left: 27px;
  }
  .bluecrcle {
    top: 114px;
    left: 217px;
  }
  .yellowcrcle {
    top: 43px;
    left: 24rem;
  } 
  .big-cont{
    display: none;
  }
}
@media(max-width : 430px){
  .plan-box>svg {
    width: 443px;
  }
  .graycrcle {
    top: 125px;
    left: 27px;
  }
  .bluecrcle {
    top: 106px;
    left: 203px;
  }
  .yellowcrcle {
    top: 43px;
    left: 22rem;
  }
  .plan-cards-container {
    margin-top: 5.5rem;
  }
  .upgrade-card2{
    margin-top: 230px;
  }
}
@media(max-width : 400px){
  .plan-box>svg {
    width: 410px;
    margin-top: 2rem;
  }
  .plan-cards-container {
    margin-top: 6.5rem;
  }
  .graycrcle {
    top: 143px;
    left: 22px;
  }
  .bluecrcle {
    top: 128px;
    left: 180px;
  }
  .yellowcrcle {
    top: 70px;
    left: 20rem;
  }
}
.post-first-col-w {
  width: 40%;
}
@media (max-width: 1198px){
  .post-first-col-w {
    width: 25%;
  }
}
@media (max-width: 485px){
  .post-first-col-w {
    width: 100%;
  }
}
.marketing-section-modal .accordion-button{
  border-radius: 16px;
  background: #FAFAFA !important;
  padding: 10px 10px 10px 15px !important;
}
.marketing-section-modal .accordion-button:not(.collapsed)::after{
  top: 0 !important;
}
.marketing-section-modal .accordion-button h5{
  font-size: 16px;
}
.marketing-section-modal .accordion-button::after{
    width: 30px;
    height: 30px;
    border-radius: 9px;
    background-color: #EEEDEE;
    background-position:center;
    margin-top: 0px !important;
}
.marketing-section-modal input,.marketing-section-modal .schedule-time{
  height: 30px;
  border-radius: 9px;
  background: #EEEDEE;
}
.marketing-section-modal .schedule-time{
  display: flex;
  align-items: center;
}
.edit-content-btn{
  border-radius: 9px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(49, 48, 67, 0.05);
  color: #03021C;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  height: 30px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  text-align: center;
  font-style: normal;
  line-height: 114.1%; /* 13.692px */
  letter-spacing: -0.18px;
}
.dot-u{
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #fff;
}
.status-u{
  width: fit-content;
  border-radius: 49px;
  display: inline-flex;
  height: 30px;
  padding: 8px 13px;
  align-items: center;
  gap: 6px;
  color: #fff;
}
.Published{
  background: #2CC657;
}
.pending:not(.domain-status-icon){
  background: #FF4242;
}
.Scheduled{
  background: #FFC01E;
}
@media (max-width: 767px){
  #wrapper .community-main .community-section .section-main {
    height: calc(var(--app-height) - 190px) !important;
}
}
.table-head-left{
  width: 100%;
  padding: 0 !important;
  padding-bottom: 10px !important;
}
.code-btn-u{
  border-radius: 11px;
  width: fit-content;
  height: 30px;
  padding: 0 8px 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: white;
  cursor: pointer;
  background: linear-gradient(180deg, #3E3E3E 0%, #4B4B4B 100%);
  box-shadow: -1px -1px 0px 0px #000 inset, 1px 2px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 1px 0px 0px #404040 inset;
}
.statusbox-u{
  display: inline-flex;
  height: 20px;
  padding:8px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.10);
  color: #333;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.3px;
 
}
.statusind{
  width: 5px;
  height: 5px;
  border-radius: 1px;
}
.greenind{
  background: #0BE714;
}
.redind{
  background: #FF1451;
}
.first_td{
  min-width: 100px;
  width: 10%;
}
.middleWidth{
  max-width: 100px;
  min-width: 80px;
  width: 10%;
}
.width-const{
  height: 30px !important;
  min-width: 86px !important;
  display: inline-flex;
  align-items: center;
}
.width-const2{
  height: 30px !important;
  min-width: 70px !important;
  display: inline-flex;
  align-items: center;
}
.cod-boxes{
  border-radius: 9px;
  background: #F3F3F3;
  text-align: left;
  padding: 7px 11px;
}
@media(max-width : 640px){
  #wrapper .community-main .community-section .section-main {
    height: calc(var(--app-height) - 235px) !important;
}
}
@media (max-width:520px) {
  .d-none_520{
    display: none;
  }
}
.edit-tag {
  display: inline-flex;
  height: 30px;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 9px;
  background: #EEEDEE;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 106.1%; /* 14.854px */
  cursor: pointer;
}
.project-new-dropdown {
  min-width: 181px;
  border-radius: 10px !important;
  background: #FFF;
  padding: 8px !important;
  box-shadow: 0px 4px 22px 0px rgba(87, 88, 111, 0.13);
  border: none;
}
.project-new-dropdown-item {
  display: flex;
  padding: 7px 9px;
  align-items: center;
  gap: 5px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.1%; /* 14.854px */
  border-radius: 9px;
}
.project-new-dropdown-item.active, .project-new-dropdown-item:hover {
  color: #fff;
  background: #18F;
}
.compare-features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #FFF;
  padding: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: 20px;
}
.monetization-main .monetization-section:has(.upgradeTeamModal),
.customize-page-main:has(.upgradeTeamModal) {
  overflow: hidden !important;
}
.date-picker-label{
  margin-top: 5px;
}
.date-picker-label>label{
  margin-bottom: 10px;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  color: #5B5B5B !important;
}
.switch-check{
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  color: #5B5B5B !important;
  font-weight: 500;
  padding-top: 5px;
}
.switch-check>input{
  max-width: 1rem;
}