#wrapper .analytics-main {
  width: 100%;
  height: calc(100dvh - 60px);
  display: flex;
  align-items: center;
  position: fixed;
}
@media (max-width:767px) {
  #wrapper .analytics-main {
    height: calc(100dvh - 115px);
}
}

#wrapper .analytics-main .analytics-section {
  flex-basis: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 25px 35px 0 35px;
  position: relative;
}

#wrapper .analytics-main .analytics-section .section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#wrapper .analytics-main .analytics-section .section-main {
  width: 100%;
}

#wrapper .analytics-main .analytics-section .section-main .analytics-card h1 {
  font-weight: 600;
  font-size: 2.2rem;
}

#wrapper .analytics-main .analytics-section .section-main .analytics-card h5 {
  font-size: 1rem;
}

#wrapper .analytics-main .analytics-section .section-main .analytics-card .analytics-badge {
  border-radius: 5px;
  font-weight: 600;
  padding: 0px 6px;
  font-size: 13px;
}

#wrapper .analytics-main .analytics-section .section-main .analytics-card .analytics-badge.analytics-badge-success {
  background-color: #D5F5E3;
  color: #0CBD5B;
}

#wrapper .analytics-main .analytics-section .section-main .analytics-card .analytics-badge.analytics-badge-danger {
  background-color: #FCD9DB;
  color: #EA1320;
}

/* #wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-blue {
  border-left: 3px solid #0C8CFB;
} */

#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-blue h1,
#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-blue h5 {
  color: #0C8CFB;
}

/* #wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-purple {
  border-left: 3px solid #7F48FB;
} */

#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-purple h1,
#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-purple h5 {
  color: #7F48FB;
}

/* #wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-pink {
  border-left: 3px solid #FB48C9;
} */

#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-pink h1,
#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-pink h5 {
  color: #FB48C9;
}

/* #wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-orange {
  border-left: 3px solid #FB9E48;
} */

#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-orange h1,
#wrapper .analytics-main .analytics-section .section-main .analytics-card.analytics-card-orange h5 {
  color: #FB9E48;
}

#wrapper .analytics-main .date-filter-dropdown,
#wrapper .analytics-main .filter-dropdown {
  padding: 7.5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
}

#wrapper .analytics-main .date-filter-dropdown::after,
#wrapper .analytics-main .filter-dropdown::after {
  display: none !important;
}

#wrapper .analytics-main .date-filter-dropdown:hover,
#wrapper .analytics-main .filter-dropdown:hover {
  background-color: #F3F3F3 !important;
}

#wrapper .analytics-main .date-filter-dropdown {
  background-color: #F3F3F3 !important;
}

#wrapper .analytics-main .date-filter-dropdown.show,
#wrapper .analytics-main .filter-dropdown.show {
  background-color: #F3F3F3 !important;
}

#wrapper .analytics-main .date-filter-dropdown-menu li a:hover,
#wrapper .analytics-main .filter-dropdown-menu li a:hover {
  background-color: #FFF !important;
}

#wrapper .analytics-main .date-filter-dropdown-menu li a span img,
#wrapper .analytics-main .filter-dropdown-menu li a span img {
  opacity: 0;
}

#wrapper .analytics-main .date-filter-dropdown-menu li a.active,
#wrapper .analytics-main .filter-dropdown-menu li a.active {
  background-color: #FFF !important;
}

#wrapper .analytics-main .date-filter-dropdown-menu li a.active span,
#wrapper .analytics-main .filter-dropdown-menu li a.active span {
  font-size: 16px !important;
}

#wrapper .analytics-main .date-filter-dropdown-menu li a.active span img,
#wrapper .analytics-main .filter-dropdown-menu li a.active span img {
  opacity: 1;
  margin-top: -4px !important;
}

#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked,
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}

#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label,
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label:before,
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 1px solid #434343;
  background: #fff;
  border-radius: 6px;
}

#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label:after,
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:not(:checked)+label:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #4397F7;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.2s ease;
  border-radius: 5px;
  transition: all 0.2s ease;
}
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label.unique_visitors:after{
  background-color: rgb(112, 238, 255);
}
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label.impressions:after{
  background-color: #0C8CFB;
}#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label.time_on_page:after{
  background-color: #7F48FB;
}#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label.link_clicks:after{
  background-color: #FFC700;
}#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label.click_rate:after{
  background-color: #F00;
}
#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

#wrapper .analytics-main .analytics-section .section-main [type=checkbox]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

#wrapper .analytics-main .analytics-section::-webkit-scrollbar {
  width: 0.5em;
}

#wrapper .analytics-main .analytics-section::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

.mbsc-calendar-cell div:not(.mbsc-calendar-cell-inner) {
  display: none !important;
}

.mbsc-calendar-cell .mbsc-calendar-cell-inner div:not(.mbsc-calendar-month-name) {
  display: inline-block !important;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #434343 !important;
  font-size: 14px !important;
}

.mbsc-icon {
  width: 1em !important;
}

.mbsc-ios.mbsc-calendar-day-text {
  background: #EFEFEF !important;
  border-radius: 7px !important;
}

.mbsc-calendar-title {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background: #4397F7 !important;
  border-color: #4397F7 !important;
  color: #fff;
}

.mbsc-ios.mbsc-datepicker-inline {
  border: none !important;
}

.text-analytics-blue {
  color: #0C8CFB !important;
}

.text-analytics-purple {
  color: #7F48FB !important;
}

.text-analytics-pink {
  color: #FB48C9 !important;
}

.text-analytics-orange {
  color: #FB9E48 !important;
}

.text-analytics-lightblue {
  color: #49DBFB !important;
}

.analytics-progress-bar {
  width: 100%;
  border-radius: 10px;
  height: 10px;
}

.analytics-progress-bar div {
  height: 10px;
  border-radius: 10px;
}

.analytics-progress-bar-large {
  height: 35px !important;
}

.analytics-progress-bar-large div {
  height: 35px !important;
}

.analytics-progress:nth-child(1) .analytics-progress-bar,
.analytics-stats:nth-child(1) .analytics-progress-bar {
  background-color: #D0E9FF;
}

.analytics-progress:nth-child(1) .analytics-progress-bar div,
.analytics-stats:nth-child(1) .analytics-progress-bar div {
  background-color: #0C8CFB;
}

.analytics-progress:nth-child(2) .analytics-progress-bar,
.analytics-stats:nth-child(2) .analytics-progress-bar {
  background-color: #E8DEFF;
}

.analytics-progress:nth-child(2) .analytics-progress-bar div,
.analytics-stats:nth-child(2) .analytics-progress-bar div {
  background-color: #7F48FB;
}

.analytics-progress:nth-child(3) .analytics-progress-bar,
.analytics-stats:nth-child(3) .analytics-progress-bar {
  background-color: #FFE2F7;
}

.analytics-progress:nth-child(3) .analytics-progress-bar div,
.analytics-stats:nth-child(3) .analytics-progress-bar div {
  background-color: #FB48C9;
}

.analytics-progress:nth-child(4) .analytics-progress-bar,
.analytics-stats:nth-child(4) .analytics-progress-bar {
  background-color: #FFEBD9;
}

.analytics-progress:nth-child(4) .analytics-progress-bar div,
.analytics-stats:nth-child(4) .analytics-progress-bar div {
  background-color: #FB9E48;
}

.analytics-progress:nth-child(5) .analytics-progress-bar,
.analytics-stats:nth-child(5) .analytics-progress-bar {
  background-color: #E4FAFF;
}

.analytics-progress:nth-child(5) .analytics-progress-bar div,
.analytics-stats:nth-child(5) .analytics-progress-bar div {
  background-color: #49DBFB;
}

.analytics-stats .bg-f3f3f3 {
  min-width: 120px;
}
.analytics-stats:not(:first-child){
  margin-top: 0.5rem !important;
}

.analytics-table thead th:nth-child(1) {
  width: 25%;
}

.analytics-table thead th:nth-child(3) {
  width: 45%;
}

.analytics-table thead th:nth-child(2),
.analytics-table thead th:nth-child(4) {
  width: 15%;
}

.analytics-table tbody td:nth-child(1) {
  width: 25%;
}

.analytics-table tbody td:nth-child(3) {
  width: 45%;
}

.analytics-table tbody td:nth-child(2),
.analytics-table tbody td:nth-child(4) {
  width: 15%;
}

.analytics-table .analytics-table-pill {
  background-color: #F3F3F3;
  border-radius: 12px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color: #434343 !important;
  font-weight: 500;
  /* height: 40px; */
}

.analytics-table .analytics-table-pill-min {
  background-color: #F3F3F3;
  border-radius: 12px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color: #434343 !important;
  font-weight: 500;
  height: 40px;
  width: 25vh;
}
@media (max-width:  993px) {
  .analytic-tab{
    overflow-x: auto;
  }
}

.analytics-table .analytics-table-pill img {
  margin-right: 8px;
}

/*# sourceMappingURL=analytics.css.map */

.analytics-main-chart canvas {
  width: 100%;
  height: 300px !important;
}
#analytics-main-chart{
  width: 100%;
  height: 300px;

}
.section_footer{
  width: 100%;
  padding: 10px 0;

}
.section_footer .table-pagination{
  
  display: flex;
  align-items: center;
  justify-content: end;
}
.section_footer .table-pagination button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: #EEEDEE;
  color: #6B6B6B;
  border-radius: 10px !important;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
}
.section_footer.table-pagination button:hover {
  background-color: #212322 !important;
  color: white !important;
}
.section_footer.table-pagination button.active {
  background-color: #212322 !important;
  color: white !important;
}

.analytics-sections {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #434343;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  max-height: 408px;
  height: auto;
  overflow: auto;
  margin-top: 25px;
  /* min-width: 400px; */
  /* align-items: center; */
}
.analytics-sections-items-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.analytics-sections-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 10px;
}
.box-ededed {
  border-radius: 3px;
  background: #EDEDED;
  padding: 0px 5px;
  display: flex;
  align-items: center;
}
.analytics-progress{
  padding-left: 0;
  padding-right: 0;
}
.analytics-progress-main {
  background: rgba(12, 138, 247, 0.14);
  border-radius: 8px;
  height: 43px;
  padding-left: 10px;
}
.analytics-progress-end {
  margin-left: -5.1rem;
  padding-right: 10px;
}
.multiple-analytics-sections {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  /* margin-top: 25px; */
}
.multiple-analytics-sections .analytics-sections {
  flex-basis: 48.8%;
}

@media (max-width: 1049px) {
  .multiple-analytics-sections .analytics-session-country,
  .multiple-analytics-sections .analytics-map,
  .multiple-analytics-sections .analytics-sections {
    flex-basis: 100% !important;
  }
}
.analytics-toggle {
  max-width: 211px;
  background: rgba(243, 243, 243, 0.55);
  padding: 3px;
}
.analytics-toggle label {
  background-color: #F3F3F3;
  padding: 8px 20px;
  text-wrap: nowrap;
  width: 100%;

}
.analytics-toggle input:checked+label {
  background: #000;
  color: #FFF;

}
.multiple-analytics-sections .analytics-session-country {
  flex-basis: 38%;
  height: 100vh;
}
.multiple-analytics-sections .analytics-map {
  flex-basis: 59%;
  height: 100vh;
}
.analytics-box {
  width: 13px;
  height: 13px;
  border-radius: 3px;
}
.analytics-type-heading {
  color: #434343;
  font-size: 12px;
  font-weight: 500;
}
.analytics-count {
  color: #03021C;
  font-size: 25px;
  font-weight: 500;
  margin-left: 15px;
}
.custom-tooltip {
  position: absolute;
  background-color: #FFF;
  border: 1px solid #F4F4F4;
  border-radius: 9px;
  padding: 15px;
  pointer-events: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  z-index: 1000;
  width:fit-content;
}


.tooltip-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.tooltip-label {
  font-weight: 500;
  color: #434343;
}

.tooltip-value {
  font-weight: 700;
  color: #434343;
}

.tooltip-title {
  font-weight: 700;
  color: #434343;
  margin-bottom: 5px;
}
.tooltip-item-first {
  display: flex;
  align-items: center;
  gap: 7px;
}
.new-analytics-table-main {
  width: 100%;
  margin-top: 25px;
  /* height: 414px; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding-bottom: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  overflow: auto;
}
.new-analytics-section-footer .section-footer{
  margin: 0 20px !important;
  /* width: auto !important; */
}
.new-analytics-table {
  background: #FFF;
  overflow: auto;
  width: 100%;
  /* height: 100%; */
}
.new-analytics-table-header {
  color: #6B6B6B;
  font-size: 14px;
  font-weight: 500;
  line-height: 111%;

}
.new-analytics-table-body {
  color: #434343;
  font-size: 12.698px;
  font-weight: 500;
  line-height: 121%; /* 15.364px */
  letter-spacing: -0.127px;
}
.new-analytics-table-header th, .new-analytics-table-body td {
  padding: 8px 25px;
}
.new-analytics-table-header tr{
  background: rgba(0, 0, 0, 0.02);
}
.new-analytics-table-tr {
  border-top: 1px solid rgba(107, 107, 107, 0.10);
  border-bottom: 1px solid rgba(107, 107, 107, 0.10);
  background: #FFF;
}
.dark-btn {
  display: flex;
  /*height: 25px;*/
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border-radius: 9px;
  background: #000;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.5s ease;
}
.dark-btn:hover{
  opacity: .9;
}
.analytics-referrer {
  max-width: 410px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.more-analytics-sections {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  height: 100%;
  width: 100%;
}
.more-analytics-sections-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  padding-bottom: 20px;
}
.more-analytics-sections.info {
  max-width: 284px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}
#showDetailedAnalytics {
  max-width: 800px;
  width: 100%;
  border-radius: 12px 0px 0px 12px;
  background: #FFF;
  box-shadow: -1px 1px 8px 0px rgba(0, 0, 0, 0.18);
}
#showDetailedAnalytics .modal-content, #showDetailedAnalytics .modal-dialog {
  max-width: 100%;
  width: 100%;
  border-radius: 12px 0px 0px 12px !important;
}

.analytics-modal-body {
  display: flex;
  gap: 15px;
}
.analytics-action {

  max-width: 255px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 5px;
}
.more-analytics-icon {
  width: 85px;
  height: 85px;
  border-radius: 100%;

}
.more-analytics-icon img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.more-analytics-details {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.07);
  display: inline-flex;
  height: auto;
  padding: 10px 27px 10px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  font-size: 15.199px;
}
.more-analytics-details .heading {
  color: #6F6E7A;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.152px;
}
.more-analytics-details .desc {
  color: #434343;
  font-size: 15.199px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.152px;
}
.project-tooltip {
  position: relative;
}

.project-tooltip-content {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  position: fixed;
  background-color: #FFF;
  border: 1px solid #F4F4F4;
  border-radius: 9px;
  padding: 20px;
  pointer-events: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  z-index: 9999;
  width:100%;
  position: absolute;
  max-width: 345px;
  width: auto;
  gap: 15px;
  border: 2px solid #F4F4F4;
  display: flex;
}
.project-tooltip:hover .project-tooltip-content{
  /* display: flex; */
  opacity: 1;
  visibility: visible;
}
.project-tooltip-content-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.project-tooltip-content-main .heading {
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}
.project-tooltip-content-main .msg {
  color: rgba(51, 51, 51, 0.80);
  font-size: 12.698px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.127px;
}
.project-tooltip-content-arrow{
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    z-index: -1;
    display: block;
    aspect-ratio: 1/1;
}
.project-tooltip-content-arrow-right {
  border-top: 2px solid #F4F4F4;
  border-right: 2px solid #F4F4F4;
  margin-left: -1px;
}
.project-tooltip-content-arrow-left {
  border-left: 2px solid #F4F4F4;
  border-bottom: 2px solid #F4F4F4;
}
.page-heading {
  color: #03021C;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 111%;
}
#wrapper .analytics-main .date-filter-dropdown {
  background-color: #f3f3f3 !important;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
}
.graph-data-arrow {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
  line-height: 121%;
  letter-spacing: -0.14px;
  height: 1px;
  text-wrap: nowrap;
}
.graph-data-arrow img{
  color:black;
  filter: brightness(0.7);
}
.rotate_neg90 {
  transform: rotate(-90deg);
}
.graph-analytics-sections {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #434343;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  /* max-height: 408px; */
  height: auto;
  overflow: auto;
  /* min-width: 400px; */
  /* align-items: center; */
}
.analytics-card-main {
  display: flex;
  align-items: center;
  gap: 1.5rem !important;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-top: 20px; */
}
@media (max-width: 1200px) {
  .analytics-card-main{
      max-width: 400px;
      row-gap: 15px !important;
  }

}
.filter-for-desktop {
  display: flex;
}
.filter-for-mobile {
  display: none;
}
@media (max-width: 790px) {
  .filter-for-mobile {
    display: flex;
    margin: 20px 0;
  }
  .filter-for-desktop {
    display: none;
  }
  .analytics-card-main {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .analytics-toggle,.analytics-sessions {
    display: none;
  }
  .analytics-session-warning {
    display: flex;
  }
  .project-tooltip {
    display: none !important;
  }
}
.analytics-session-warning {
  margin-bottom: 25px;
  border-radius: 6.458px;
  background: rgba(247, 209, 12, 0.13);
  box-shadow: 0px 0px 3.229px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  align-items: center;
  gap: 10px;
  color: #434343;
  font-size: 12.27px;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: -0.123px;
  padding: 10px;
  display:none;
}
.analytics-map {
  position: relative;
}
.analytics-map .date-filter-dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
#showDetailedAnalytics.modal-dialog,#showDetailedAnalytics .modal-dialog {
  transform: none !important;
  width: 100%;
  max-width: 800px;
}
#showDetailedAnalytics .modal-dialog,
#showDetailedAnalytics,
#showDetailedAnalytics .modal-content{
  transition: 0.5s ease;
}
.posts-analytics-order {
  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #F3F3F3;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
  color: #5B5B5B;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 1;
  cursor: pointer;
  border: none;
}
.posts-analytics-order img {
  filter: invert(0.4);
  width: 11px;
  height: 7px;
  transform: rotate(0deg);
  transition: 0.4s ease;

}
.posts-analytics-order.desc img {
  transform: rotate(180deg) !important;
}
.posts-analytics-section {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  gap: 26px;
}
.posts-analytics-post{
  border-radius: 19.12px;
  background: #1D4953;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08);
  max-width: 233px;
  height: 209px;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  justify-content: space-between;
}
.posts-analytics-post:hover {
  box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.08);
}
.posts-analytics-post img{
  width: 100%;
  max-width: 210.959px;
  max-height: 122.295px;
  height: 100%;
  border-radius: 14.659px;

}
.post-heading {
  font-size: 14.021px;
  font-weight: 500;
  max-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 196.3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.post-description {
  font-size: 10.197px;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 196.3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.calendar-input-container {
  display: inline-flex;
  gap: 14px;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  font-size: 14.581px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.146px;
  width: 85%;
}
.calendar-container {
  display: flex;
  gap: 14px;
  flex-direction: column;
}
.calendar-button-container {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}
.calendar-button-container button {
  color: #FFF;
  font-size: 14.581px;
  font-weight: 500;
  letter-spacing: -0.146px;
  border-radius: 7px;
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 7.674px;
  border: none;
}
.cancel-dates {
  background-color: #5F5F5F;
}
.apply-dates {
  background-color: #1188FF;
}
.calendar-input-container label {
  max-width: 67px;
  width: 100%;
  font-size: 14px;
}
.graph-data-arrow.rotate_neg90{
  width: 20px;
}
.sidebar-sub-menu-main .dropdown-arrow {
  transition: .3s ease;
}
.sidebar-sub-menu-main.active .dropdown-arrow {
  transform: rotate(180deg);
}
.links-analytics-main {
  border-radius: 8.927px;
  background: #FFF;
  box-shadow: 0px 0px 2.976px 0px rgba(0, 0, 0, 0.16);
  min-width: 257px;
  max-height: 97px;
  height: 100%;
  display: flex;
  gap: 15px;
  padding: 10px;
  align-items: flex-start;
  cursor: pointer;
  transition: 0.5s ease;
  min-height: 85px;

}
.links-analytics-main:hover{
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.16);
}
.links-analytics-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 25px;
}
.links-analytics-main .favicon-img {
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.links-analytics-main .first-half {
  border-radius: 5.951px;
  background: #FFF;
  box-shadow: 0px 0px 2.976px 0px rgba(0, 0, 0, 0.07);
  display: inline-flex;
  max-height: 50px;
  padding: 7.441px 20.085px 6.828px 7.439px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.854px;
  color: #434343;
  font-size: 11.307px;
  font-weight: 500;
  line-height: 121%;
  letter-spacing: -0.113px;
  width: 100%;
} 
.links-analytics-main .clicks {
  display: inline-flex;
  padding: 5.441px 79.561px 5.559px 5.439px;
  align-items: center;
  border-radius: 5.951px;
  background: #FFF;
  box-shadow: 0px 0px 2.976px 0px rgba(0, 0, 0, 0.07);
  color: #6F6E7A;
  font-size: 11.307px;
  font-weight: 500;
  line-height: 121%; /* 13.681px */
  letter-spacing: -0.113px;
}
.link-analytics-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}
.link-analytics-header .links-analytics-main {
  width: 100%;
}
@media (max-width: 940px){
  .link-analytics-header {
    flex-direction: column;

  }
}

body:has(.custom-tooltip){
  overflow-x:hidden ;
}

@media (max-width: 549px) {
  #wrapper .analytics-main .analytics-section {
    padding: 15px !important;
    margin: 0 !important;
  }
}